import * as React from 'react'
import PageView from '../views/pageView'
import { Link } from 'gatsby'

export default function ThankYou() {
  return (
    <PageView>
      <section>
        <div className="container">
          <div className="row py-5">
            <div className="col">
              <h1 className="h4 mb-5">Thank you for contacting us!</h1>
              <Link to="/" className="btn btn-secondary">
                Return to Juan Ortiz Guitar
              </Link>
            </div>
          </div>
        </div>
      </section>
    </PageView>
  )
}
